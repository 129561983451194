import React from "react"
import styled from "styled-components"
import "../style.css"
import { Link } from "gatsby"

const TagsBox = ({ tags, title }) => {
  return (
    <>
      <Heading>Tags</Heading>
      <Caption>Topics tagged in this post</Caption>
      <BubbleGroup>
        {tags &&
          tags.map((tag, index) => (
            <Link to={`/tags/${tag}`} style={{ textDecoration: "none" }}>
              <TagBox key={index}>{tag}</TagBox>
            </Link>
          ))}
      </BubbleGroup>
    </>
  )
}

export default TagsBox
const BubbleGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const TagBox = styled.div`
  font-size: var(--fontSize-2);
  border: 1px solid var(--color-text);
  padding: 2px 5px;
  margin-right: 8px;
  margin-top: 8px;
  white-space: nowrap;
  border-radius: 5px;
  a:hover,
  a:focus {
    color: var(--color-heading-hover);
  }
`
const Heading = styled.h3`
  font-size: 24px;
  text-align: center;
  color: var(--color-export-pink);
  margin-bottom: 0.7rem;
`
const Caption = styled.p`
  font-size: 16px;
  text-align: center;
  color: var(--color-text);
  margin-bottom: 0.7rem;
`
